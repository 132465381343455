<template>
  <v-card class="mx-auto" max-width="1000" max-height="1000">
    <v-card-title>
      {{ $t("Thank You") }}
    </v-card-title>
    <v-divider></v-divider>

    <v-card-subtitle>
      {{ message }}
    </v-card-subtitle>

    <v-card-subtitle>
      {{ $t("Redirect To") }} <a href="/"> {{ $t("Home") }} </a>
    </v-card-subtitle>
  </v-card>
</template>

<script>
import axios from "axios";
export default {
  name: "paymentRedirect",
  data() {
    return {
      message: "",
      payment_id: "",
      moyasar_payment_id: ""
    };
  },
  methods: {
    // checkPayemntStatus() {
    //   axios
    //     .post(
    //       this.getApiUrl + "/online_payment/checkPayment/" + this.payment_id,
    //       { payment_id: this.moyasar_payment_id },
    //       {
    //         headers: {
    //           Authorization: "Bearer " + localStorage.token
    //         }
    //       }
    //     )
    //     .then(response => {
    //       // window.location = response;
    //       this.message = response.data.data.message;
    //       console.log(response);
    //     });
    // },
    checkPayment() {
      axios
        .get(
          this.getApiUrl + "/PymentAmountHyperPay/" + this.moyasar_payment_id
        )
        .then(response => {
          // window.location = response;
          let data = response.data.data;
          this.message = response.data.data.result.description;
          if (data.result.code.match(/^(000.000.|000.100.1|000.[36])/)) {
            axios
              .get(
                this.getApiUrl +
                  "/online_payment/checkPaymentHyperPay/" +
                  this.payment_id +
                  "/" +
                  this.moyasar_payment_id
              )
              .then(response => {
                console.log(response);
              });
          }

          //   console.log(response);
        });
    }
  },
  mounted() {
    this.moyasar_payment_id = this.$route.query.id;
    this.payment_id = this.$router.currentRoute.params.payment_id;
    // this.checkPayemntStatus();
    this.checkPayment();
  }
};
</script>
